<template>
  <AppView>
    <!-- content @s -->
    <div class="nk-content nk-content-fluid">
      <div class="container-xl wide-xl">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="nk-block-head nk-block-head-sm">
              <div class="nk-block-between g-3">
                <div class="nk-block-head-content">
                  <h3 class="nk-block-title page-title">Editar cuenta</h3>
                </div>
              </div>
            </div><!-- .nk-block-head -->
            <div class="nk-block">
              <div class="card card-bordered">
                <div class="card-aside-wrap">
                  <div class="card-content">
                    <ul class="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                      <li class="nav-item">
                        <a class="nav-link active" href="#"><em class="icon ni ni-user-circle"></em><span>Cuenta</span></a>
                      </li>
                    </ul><!-- .nav-tabs -->
                    <form @submit.prevent="save">
                      <div class="card-inner">
                        <div class="nk-block">
                          <div class="nk-block-head">
                            <h5 class="title">Configuración de la cuenta</h5>
                            <p>Datos básicos como nombre, correo electrónico, etc.</p>
                          </div><!-- .nk-block-head -->
                          <div class="profile-ud-list">
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Nombre</span>
                                <span class="profile-ud-value">
                                    <input type="text" class="form-control" autocomplete="nope" v-model="user_edit.name" required>
                                </span>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Apellidos</span>
                                <span class="profile-ud-value">
                                    <input type="text" class="form-control" autocomplete="nope" v-model="user_edit.lastname" required>
                                </span>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Usuario</span>
                                <span class="profile-ud-value text-capitalize text-left">
                                  <input type="text" class="form-control" autocomplete="nope" v-model="user_edit.username" required>
                                </span>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Correo</span>
                                <span class="profile-ud-value">
                                    <input type="email" class="form-control" autocomplete="email" v-model="user_edit.email" required>
                                </span>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Contraseña</span>
                                <span class="profile-ud-value">
                                    <input type="password" class="form-control" autocomplete="new-password" v-model="password">
                                    <em class="text-soft fs-12px text-left w-100 d-inline-block">Si no deseas cambiar tu contraseña, deja este campo vacío</em>
                                </span>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Repetir Contraseña</span>
                                <span class="profile-ud-value">
                                    <input type="password" class="form-control" autocomplete="new-password" v-model="password_confirm">
                                    <em v-if="!valid" class="text-danger fs-12px text-left w-100 d-inline-block">Las contraseñas no son iguales</em>
                                </span>
                              </div>
                            </div>
                          </div><!-- .profile-ud-list -->
                          <div class="w-100 text-right">
                            <button type="submit" class="mt-2 btn btn-lg btn-primary" :disabled="!valid">Actualizar Cuenta</button>
                          </div>
                        </div><!-- .nk-block -->
                      </div><!-- .card-inner -->
                    </form>
                  </div><!-- .card-content -->
                </div><!-- .card-aside-wrap -->
              </div><!-- .card -->
            </div><!-- .nk-block -->
          </div>
        </div>
      </div>
    </div>
    <!-- content @e -->
  </AppView>
</template>

<script>
import AppView from "@/components/sistema/AppView";
import useAuth from '@/providers/auth';
import { ref, computed } from 'vue';
import Swal from "sweetalert2";
import {useApi} from "@/use/useApi";

export default {
  name: "Editar Usuario",
  components: {AppView},
  setup(){
    const { user } = useAuth();
    const { loading, make } = useApi();

    const user_edit = ref({...user.value});
    const password = ref('');
    const password_confirm = ref('');

    const valid = computed(() =>{
      if(password.value.length > 0){
        if(password.value !== password_confirm.value){
          user_edit.value.password = null;
          return false;
        } else {
          user_edit.value.password = password.value;
        }
      }
      return true;
    });

    loading.message = 'Actualizando cuenta';

    async function save(){
      let {data} = await make('administrators/edit', {user: user_edit.value});
      if(data === null){
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          Swal.fire('Error', data.message, 'error');
        } else {
          Swal.fire('Éxito', data.message, 'success');
          //Actualizar información del auth global
          user.value = data.user;
          //Limpiar contraseñas
          password.value = '';
          password_confirm.value = '';
        }
      }
    }

    return {user_edit, password, password_confirm, loading, valid, save};
  }
}
</script>

<style scoped>
.profile-ud-list {
  max-width: inherit;
}
.profile-ud-label {
  width: 130px;
  align-items: start;
  margin-top: 0.5em;
}
.profile-ud-value {
  line-height: 2.5em;
}
</style>